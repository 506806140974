import React from 'react';
import IntlMessages from '../helpers/IntlMessages';

export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'ru';
export const localeOptions = [
  { id: 'ua', name: 'Українська', direction: 'ltr' },
  { id: 'ru', name: 'Русский', direction: 'ltr' },
];

export const selectUserStatusData = [
  { label: <IntlMessages id="status_0" />, value: 0, key: 0 },
  { label: <IntlMessages id="status_1" />, value: 1, key: 1 },
  { label: <IntlMessages id="status_2" />, value: 2, key: 2 },
];

export const selectAllowPublishData = [
  { label: <IntlMessages id="off" />, value: 0, key: 0 },
  { label: <IntlMessages id="on" />, value: 1, key: 1 },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const buyUrl = process.env.REACT_APP_SITE_URL;
export const searchPath = `${adminRoot}/#`;
export const servicePath = process.env.REACT_APP_API_URL;

export const getCurrentUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem('gogo_current_user'));
    return user || null;
  } catch (error) {
    console.error('Error in getCurrentUser:', error);
    return null;
  }
};
export const currentUser = getCurrentUser() || {
  id: 1,
  title: 'Sergio Ex',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: 0,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
